@use '../../styles/themes' as *;
@use '../inputStylesCore' as *;

@include inputStylesCore;

@include themify(input) {
  .input {
    font-family: inherit;
    font-size: inherit;
    padding: 0.5rem;
    resize: none;
  }

  .multiExample {
    height: 150px;
  }
}
