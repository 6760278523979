$themesColors: (
  defaultThemeColors: (
    // buttons, auction search results headings
    primary: #274294,
    // header & footer background, info block
    secondary: #141414,
    // surfaces: card background
    light: #fff,
    // Iconography (symbols)
    dark: #1c2830,
    // page backgrounds, text color on secondary
    background: #f9f9f9,
    // vertical nav, view gallery button, muted buttons
    muted: #9b9b9b,
    // paid invoice
    success: #33b06a,
    // place bid button, auction/ring info, Info notification
    info: #3a84b5,
    // Warning! Warning notification
    warning: #f9c606,
    // Error notification
    danger: #df4c37,
    // Color for hovering (dropdown options, etc.)
    hover: #f3f2f1,
    // Color for selected items (selected dropdown option, etc.)
    select: #edebe9
  ),
  garishChristmasColors: (
    // buttons, auction search results headings
    primary: green,
    // header & footer background, info block
    secondary: red,
    // surfaces: card background
    light: lightgreen,
    // Iconography (symbols)
    dark: rgb(70, 31, 31),
    // page backgrounds, text color on secondary
    background: rgb(16, 11, 83),
    // vertical nav, view gallery button, muted buttons
    muted: rgb(95, 24, 24),
    // paid invoice
    success: #33b06a,
    // place bid button, auction/ring info, Info notification
    info: #3a84b5,
    // Warning! Warning notification
    warning: #f9c606,
    // Error notification
    danger: #df4c37
  )
);

$colorMapToUseString: null;
@function color($key) {
  @if $colorMapToUseString == null {
    @return map-get(map-get($themesColors, defaultThemeColors), $key);
  } @else {
    @return map-get(map-get($themesColors, $colorMapToUseString), $key);
  }
}

$themes: (
  /*
  - Organize sections alphabetically
  - Prefer shorthand properties for easier updating
  - Sections (as much as possible) should correspond to reusable
  components. Colors should be organized by rank,


  i.e. Primary, Secondary, Accent.
  - Primary is most frequently used
  - Secondary is less frequently used
  - Accent is least frequently used

  See: https://m2.material.io/design/color/the-color-system.html#color-theme-creation

  - General site-wide usages shall live in `defaultSection`.
  - 'color' is text color, 'background' is background shorthand (often background color)
  */
    defaultTheme:
    (
      button: (
        background: color(primary),
        color: color(light),
        mutedBackground: color(muted),
        zLevel: 100
      ),
      defaultSection: (
        brand: color(primary),
        pageBackground: color(background),
        pageColor: color(dark),
        primaryBackground: color(primary),
        primaryColor: color(light),
        secondaryBackground: color(secondary),
        secondaryColor: color(light),
        mutedBackground: color(muted),
        mutedColor: color(light),
        // surfaces are e.g. cards
        surfaceBackground: color(light),
        surfaceColor: color(dark),
        iconColor: color(dark),
        successBackground: color(success),
        successColor: color(dark),
        infoBackground: color(info),
        infoColor: color(light),
        warningBackground: color(warning),
        warningColor: color(dark),
        dangerBackground: color(danger),
        dangerColor: color(dark),
        shadow: rgba(color(dark), 0.25),
        light: color(light),
        dark: color(dark),
        hover: color(hover),
        zLevel: 0
      ),
      footer: (
        background: color(secondary),
        color: color(light),
        zLevel: 800
      ),
      header: (
        background: color(secondary),
        color: color(light),
        shadow: rgba(color(dark), 0.25),
        infoBackground: color(info),
        infoColor: color(light),
        warningBackground: color(warning),
        warningColor: color(dark),
        dangerBackground: color(danger),
        dangerColor: color(light),
        brand: color(primary),
        zLevel: 900
      ),
      input: (
        background: color(light),
        color: color(dark),
        placeholderColor: color(muted),
        errorColor: color(danger),
        hover: color(hover),
        select: color(select),
        shadow: rgba(color(dark), 0.25),
        info: color(info),
        zLevel: 200
      ),
      loadingOverlay: (
        zLevel: 500
      ),
      modal: (
        overlay: rgba(color(dark), 0.21),
        shadow: rgba(color(dark), 0.25),
        arrowBorder: rgba(color(dark), 0.1),
        background: color(light),
        color: color(dark),
        borderRadius: 7px,
        contentPadding: 0 1rem,
        zLevel: 1000
      ),
      skeleton: (
        color: lighten(color(muted), 25%),
        shimmer: color(light),
        zLevel: 300
      )
    )
);

$colorMapToUseString: 'garishChristmasColors';

$garishChristmas: (
  garishChristmas: (
    button: (
      background: color(primary),
      color: color(light),
      mutedBackground: color(muted)
    ),
    defaultSection: (
      pageBackground: color(background),
      pageColor: color(dark),
      primaryBackground: color(primary),
      primaryColor: color(light),
      secondaryBackground: color(secondary),
      secondaryColor: color(light),
      mutedBackground: color(muted),
      mutedColor: color(light),
      // surfaces are e.g. cards
      surfaceBackground: color(light),
      surfaceColor: color(dark),
      iconColor: color(dark),
      successBackground: color(success),
      successColor: color(dark),
      infoBackground: color(info),
      infoColor: color(dark),
      warningBackground: color(warning),
      warningColor: color(dark),
      dangerBackground: color(danger),
      dangerColor: color(dark),
      shadow: rgba(color(dark), 0.25),
      light: color(light),
      dark: color(dark)
    ),
    footer: (
      background: color(secondary),
      color: color(light)
    ),
    header: (
      background: color(secondary),
      color: color(light),
      shadow: rgba(color(dark), 0.25),
      infoBackground: color(info),
      infoColor: color(light),
      warningBackground: color(warning),
      warningColor: color(dark),
      dangerBackground: color(danger),
      dangerColor: color(light)
    ),
    input: (
      background: color(light),
      color: color(dark),
      placeholderColor: color(muted),
      errorColor: color(danger),
      hover: color(info)
    ),
    modal: (
      overlay: rgba(color(dark), 0.21),
      shadow: rgba(color(dark), 0.25),
      arrowBorder: rgba(color(dark), 0.1),
      background: color(light),
      color: color(dark)
    ),
    skeleton: (
      color: lighten(color(muted), 25%),
      shimmer: color(light)
    )
  )
);

$themes: map-merge($themes, $garishChristmas);

// Some implementations of Sass (Dart) require globals to be declared in the root
$extractedMapOfProperties: null;
$currentThemeName: null;
$currentZLevel: null;

@mixin themify(
  $sectionNameString: 'defaultSection',
  $isTopLevel: true,
  $mapOfThemes: $themes,
  $isModule: true,
  $isInThemeWrapper: false
) {
  // We capture the value of the global before mutating it so we can restore it in case we are nested in
  // a wrapping themify mixin (ourself)
  $extractedMapOfPropertiesToRestore: $extractedMapOfProperties;

  $isNestedInSelf: null;
  @if $extractedMapOfPropertiesToRestore != null {
    $isNestedInSelf: true;
  }

  // If not nested, we need to iterate through all the themes in $mapOfThemes
  @if not $isNestedInSelf {
    @each $themeName, $mapOfSections in $mapOfThemes {
      @include apply-theme(
        $themeName,
        $mapOfSections,
        $isTopLevel,
        $sectionNameString,
        $isInThemeWrapper,
        $isModule,
        $extractedMapOfPropertiesToRestore,
        $isNestedInSelf
      ) {
        @content;
      }
    }
    $currentThemeName: null;
    // Else If nested, we only want to use currentThemeName;
    // And we don't want to mutate currentThemeName (which is global) for wrapping themify
  } @else {
    $themeName: $currentThemeName;
    $mapOfSections: map-get($mapOfThemes, $themeName);
    @include apply-theme(
      $themeName,
      $mapOfSections,
      $isTopLevel,
      $sectionNameString,
      $isInThemeWrapper,
      $isModule,
      $extractedMapOfPropertiesToRestore,
      $isNestedInSelf
    ) {
      @content;
    }
  }
}

@mixin apply-theme(
  $themeName,
  $mapOfSections,
  $isTopLevel,
  $sectionNameString,
  $isInThemeWrapper,
  $isModule,
  $extractedMapOfPropertiesToRestore,
  $isNestedInSelf
) {
  $currentThemeName: $themeName !global;
  // If we're the defaultTheme or nested in ourself we must not apply .theme-#{$themeName}
  // as defaultTheme doesn't use it and it's already been applied to us if we're nested in ourself
  @if $themeName == 'defaultTheme' or $isNestedInSelf {
    // Apply default theme content directly to the page,
    // else to the property body of the selector it's included in
    @if $isTopLevel {
      @include build-local-theme-map(
        $mapOfSections,
        $sectionNameString,
        $extractedMapOfPropertiesToRestore
      ) {
        @content;
      }
    } @else {
      & {
        @include build-local-theme-map(
          $mapOfSections,
          $sectionNameString,
          $extractedMapOfPropertiesToRestore
        ) {
          @content;
        }
      }
    }
  } @else {
    // Handle case where we are using @themify in the element that has
    // the .theme-#{$themeName} class applied
    @if $isInThemeWrapper {
      .theme-#{$themeName} {
        /* Special handling of theme wrapper itself */
        @include build-local-theme-map(
          $mapOfSections,
          $sectionNameString,
          $extractedMapOfPropertiesToRestore
        ) {
          @content;
        }
      }
    } @else if $isTopLevel {
      // Put theme styling in a global class selector.
      // Note, as long as the getValue function is used inside
      // a selector that's inside the passed in styles to themify mixin, we won't have
      // style conflicts because the selectors in the passed in @content have module suffixes.
      // E.g. .theme-garishChristmas .styles_parent__5ovGs { }

      @if $isModule {
        :global(.theme-#{$themeName}) {
          @include build-local-theme-map(
            $mapOfSections,
            $sectionNameString,
            $extractedMapOfPropertiesToRestore
          ) {
            @content;
          }
        }
      } @else {
        .theme-#{$themeName} {
          @include build-local-theme-map(
            $mapOfSections,
            $sectionNameString,
            $extractedMapOfPropertiesToRestore
          ) {
            @content;
          }
        }
      }
    } @else {
      @if $isModule {
        :global(.theme-#{$themeName}) & {
          @include build-local-theme-map(
            $mapOfSections,
            $sectionNameString,
            $extractedMapOfPropertiesToRestore
          ) {
            @content;
          }
        }
      } @else {
        .theme-#{$themeName} & {
          @include build-local-theme-map(
            $mapOfSections,
            $sectionNameString,
            $extractedMapOfPropertiesToRestore
          ) {
            @content;
          }
        }
      }
    }
  }
}

@mixin build-local-theme-map(
  $mapOfSections,
  $sectionNameString,
  $extractedMapOfPropertiesToRestore
) {
  $extractedMapOfProperties: () !global;

  $mapOfProperties: map-get($mapOfSections, $sectionNameString);
  @each $key, $propertyValue in $mapOfProperties {
    $extractedMapOfProperties: map-merge(
      $extractedMapOfProperties,
      (
        $key: $propertyValue
      )
    ) !global;
  }

  @content;

  $extractedMapOfProperties: $extractedMapOfPropertiesToRestore !global;
}

@function getValue($key) {
  @return map-get($extractedMapOfProperties, $key);
}

// Themed keyframes:
// Vanilla css keyframes are global in scope so we have to create
// and access unique names for each theme using the below
@function getKeyframesThemedName($nameBase) {
  @return #{$nameBase}-#{$currentThemeName};
}

@mixin animationNameForKeyframes($nameBase) {
  animation-name: getKeyframesThemedName($nameBase);
}

@mixin keyframesThemed($nameBase) {
  @keyframes #{getKeyframesThemedName($nameBase)} {
    @content;
  }
}

// Defines `color:` properties for text in order for it to contrast with the background,
// defaulting color to dark
// and then an `.alternateTextColor` class in case a given background is too similar to
// the default color.
// The default and alternate colors are the theme's defaultSection dark and light color defs

// Also takes additionalColorProperties map as its first argument
// so e.g. border-color can also be set

/**
@example
.outline {
    border-radius: 5px;
    border: 1px solid;
    background: transparent;
    @include textColorForTransparentBG([border-color]);
        returns:
        (  color: #000; border-color: #000
          .alternateTextColor {color: #FFF, borderColor: #FFF;}
        )
  }
**/

@mixin textColorForTransparentBG(
  $additionalColorProperties: null,
  $isDefaultDark: true
) {
  $default: null;
  $alternate: null;

  @include themify() {
    @if $isDefaultDark {
      $default: getValue(dark);
      $alternate: getValue(light);
    } @else {
      $default: getValue(light);
      $alternate: getValue(dark);
    }

    // Basic returned styling
    color: $default;
    .alternateTextColor {
      color: $alternate;
    }

    // Handle additional properties if passed in
    @if type-of($additionalColorProperties) == list {
      @each $property in $additionalColorProperties {
        #{$property}: $default;

        .alternateTextColor {
          #{$property}: $alternate;
        }
      }
    } @else if $additionalColorProperties != null {
      @error "Argument $additionalColorProperties must be a list, not a #{type-of($additionalColorProperties)}";
    }
  }
}

// Retrieves absolute z-index based on the zLevel property of the section it is used in and the relativeIndex argument.
// Only for use within the @themify() mixin content block.
@mixin zIndex($relativeIndex) {
  @if $extractedMapOfProperties == null {
    @error "$extractedMapOfProperties is null, which likely indicates that this mixin is not being used in the content block of the @themify mixin.";
  }
  @if type-of($relativeIndex) != 'number' {
    @error "The $relativeIndex argument must be of type number.";
  }
  @if not(-1 < $relativeIndex) or not ($relativeIndex < 99) {
    @error "$relativeIndex must be a value from 0 to 99.";
  }

  $currentZLevel: map-get(
    $map: $extractedMapOfProperties,
    $key: zLevel
  );
  z-index: $currentZLevel + $relativeIndex;
}

@mixin darkeningOverlay(
  $background: rgba(getValue(secondaryBackground), 0.5),
  $className: '.darkeningOverlay'
) {
  #{$className} {
    background: $background;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    mix-blend-mode: multiply;
    @content;
  }
}

@mixin getButtonStyle($variant: 'solid') {
  @include themify(button) {
    @if $variant == 'solid' {
      border-radius: 5px;
      background: getValue(background);
      color: getValue(color);
      border: none;

      &:hover:not(.disabled),
      &:active:not(.disabled),
      &:focus:not(.disabled) {
        transition: all 0.25s;
        background: darken(getValue(background), 30%);
      }
    } @else if $variant == 'outline' {
      border-radius: 5px;
      border: 1px solid;
      background: transparent;
      @include textColorForTransparentBG([border-color]);

      &:hover:not(.disabled),
      &:active:not(.disabled),
      &:focus:not(.disabled) {
        transition: all 0.25s;
        background: darken(getValue(background), 30%);
        color: getValue(color);
      }

      &.disabled {
        svg,
        g {
          fill: getValue(background);
        }
      }
    } @else if $variant == 'ghost' {
      border-radius: 5px;
      border: none;
      background: transparent;
      @include textColorForTransparentBG();

      &:hover:not(.disabled),
      &:active:not(.disabled),
      &:focus:not(.disabled) {
        transition: all 0.25s;
        background: darken(getValue(background), 30%);
        color: getValue(color);
      }

      &.disabled {
        svg,
        g {
          fill: getValue(background);
        }
      }
    } @else if $variant == 'link' {
      border-radius: 5px;
      border: none;
      background: transparent;
      @include textColorForTransparentBG();

      &:hover:not(.disabled),
      &:active:not(.disabled),
      &:focus:not(.disabled) {
        @include themify() {
          transition: all 0.25s;
          color: lighten(getValue(dark), 30%);
        }
      }

      &.disabled {
        svg,
        g {
          fill: getValue(background);
        }
      }
    }
  }
}

$paymentsColors: (
  paymentsColors: (
    // buttons, auction search results headings
    primary: #3a84b5,
    // header & footer background, info block
    secondary: #f9f9f9,
    // surfaces: card background
    light: #fff,
    // Iconography (symbols)
    dark: #1c2830,
    // page backgrounds, text color on secondary
    background: #f9f9f9,
    // vertical nav, view gallery button, muted buttons
    muted: #9b9b9b,
    // paid invoice
    success: #3a84b5,
    // place bid button, auction/ring info, Info notification
    info: #3a84b5,
    // Warning! Warning notification
    warning: #f9c606,
    // Error notification
    danger: #df4c37
  )
);

$themesColors: map-merge($themesColors, $paymentsColors);

$colorMapToUseString: 'paymentsColors';

$paymentsThemes: (
  paymentsDefault: (
    button: (
      background: color(primary),
      color: color(light),
      mutedBackground: color(muted),
      zLevel: 100
    ),
    defaultSection: (
      pageBackground: color(background),
      pageColor: color(dark),
      primaryBackground: color(primary),
      primaryColor: color(light),
      secondaryBackground: color(secondary),
      secondaryColor: color(light),
      mutedBackground: color(muted),
      mutedColor: color(light),
      // surfaces are e.g. cards
      surfaceBackground: color(light),
      surfaceColor: color(dark),
      iconColor: color(dark),
      successBackground: color(success),
      successColor: color(dark),
      infoBackground: color(info),
      infoColor: color(dark),
      warningBackground: color(warning),
      warningColor: color(dark),
      dangerBackground: color(danger),
      dangerColor: color(dark),
      shadow: rgba(color(dark), 0.25),
      light: color(light),
      dark: color(dark),
      zLevel: 0
    ),
    footer: (
      background: color(secondary),
      color: color(light),
      zLevel: 800
    ),
    header: (
      background: color(secondary),
      color: color(light),
      shadow: rgba(color(dark), 0.25),
      zLevel: 900
    ),
    input: (
      background: color(light),
      color: color(dark),
      placeholderColor: color(muted),
      errorColor: color(danger),
      zLevel: 200
    ),
    loadingOverlay: (
      zLevel: 500
    ),
    modal: (
      overlay: rgba(color(dark), 0.21),
      shadow: rgba(color(dark), 0.25),
      background: color(light),
      color: color(dark),
      zLevel: 400
    ),
    skeleton: (
      color: lighten(color(muted), 25%),
      shimmer: color(light),
      zLevel: 300
    )
  )
);

$themes: map-merge($themes, $paymentsThemes);
