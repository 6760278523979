.layouts_standard.parent {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  box-shadow: 0px 1px 4px 0px theme-color('borderFrame');

  .layouts_standard.wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    width: 100%;

    .footer {
      padding: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      background-color: theme-color('nav-bottom');

      @include respond-to(large) {
        flex-direction: row;
      }

      .image {
        width: 100%;
        height: auto;
        display: block;
        margin: auto;
      }

      .footer-links {
        display: flex;
        flex-direction: column;
        width: fit-content;
        margin: auto;
        a {
          color: white;
          padding: 0.5rem;
          text-decoration: none;

          &:hover {
            color: theme-color('primary');
          }
        }
      }
    }
  }
  .layouts_standard.mobile {
    .mobile-navbar {
      justify-content: flex-end;
      .navbar-toggler {
        margin-right: 2rem;
      }
    }

    .nav-elements {
      display: none;
    }

    .nav-elements.active {
      display: block;
      position: absolute;
      background-color: theme-color('primary');
      width: 100%;
      top: 50px;
      z-index: 10;

      .dropdown-container {
        .dropdown-button {
          border: none;
          outline: none;
          color: white;
          padding: 0.5rem;
          background-color: inherit;
          font-family: inherit;
          margin: 0;
          height: 100%;

          &:hover {
            background-color: theme-color('hover');
          }
        }

        .dropdown-content {
          color: white;
          display: none;
          position: absolute;
          background-color: theme-color('secondary');
          min-width: 160px;
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
          z-index: 1;
        }

        &:hover {
          .dropdown-content {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }

    .logo-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 900px;
      margin-right: 2rem;
      margin-left: 2rem;

      .logo {
        width: 100%;
        height: auto;
        display: block;
        margin: auto;
      }
    }

    .mobile-navbar {
      background-color: theme-color('primary');
    }
  }

  .layouts_standard.header {
    display: flex;
    flex-direction: column;
    width: 100%;
    top: 0;
    z-index: 2;
    background-color: white;

    .haaby_header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      padding: 2rem;

      .image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 300px;
      }

      .image {
        width: 100%;
        height: auto;
        display: block;
        margin: auto;
      }

      .logo-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 900px;
        margin-right: 2rem;
        margin-left: 2rem;
      }

      .logo {
        width: 100%;
        height: auto;
        display: block;
        margin: auto;
      }

      .name {
        font-size: 1rem;

        @include respond-to(medium) {
          font-size: 1.5rem;
        }

        @include respond-to(large) {
          font-size: 3rem;
        }
      }

      .title {
        font-size: 0.75rem;

        @include respond-to(large) {
          font-size: 0.75rem;
        }

        @include respond-to(large) {
          font-size: 1.5rem;
        }
      }
    }

    .haaby_nav {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: theme-color('primary');
      font-size: 1rem;

      a {
        color: white;
        padding: 0.5rem;
        text-decoration: none;

        &:hover {
          background-color: theme-color('hover');
        }
      }

      @include respond-to(large) {
        font-size: 1.5rem;
      }

      .dropdown-container {
        .dropdown-button {
          border: none;
          outline: none;
          color: white;
          padding: 0.5rem;
          background-color: inherit;
          font-family: inherit;
          margin: 0;
          height: 100%;

          &:hover {
            background-color: theme-color('hover');
          }
        }

        .dropdown-content {
          color: white;
          display: none;
          position: absolute;
          background-color: theme-color('secondary');
          min-width: 160px;
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
          z-index: 1;
        }

        &:hover {
          .dropdown-content {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }

  .layouts_standard.overflow {
    display: flex;
    flex-direction: column;
    min-height: 50%;
    padding: 2rem;

    .customframe {
      width: 100%;
      height: auto;
      min-height: 800px;
    }
  }

  .layouts_standard.overflow.paddingLeft {
    padding-left: 2rem;
  }

  .layouts_standard.overflow.padding {
    padding: 0 2rem;
  }
}

.layouts_standard.parent.margin {
  @include respond-to(small) {
    margin: 2rem;
  }
}

.layouts_standard.parent.capHeight {
  max-height: calc(100vh - 56px);

  @include respond-to(small) {
    max-height: calc(100vh - 56px - 4rem);
  }
}
