@import './custom.scss';
@import './pages/index.scss';
@import './components/index.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 2rem;
  color: theme-color('primary');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.haaby-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  max-width: 1200px;
  margin: auto;

  @include respond-to(medium) {
    flex-direction: row;
  }
}

.haaby-col {
  display: flex;
  flex-direction: column;
}

.contact-parent {
  width: 100%;
  max-width: 600px;
  flex-direction: column;
  display: flex;
  flex: 1;
  justify-self: center;
  align-self: center;
}

.check-container {
  @include respond-to(medium) {
    padding-bottom: 1rem;
  }
}

.check-row {
  flex-wrap: wrap;
}
