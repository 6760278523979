@use '../styles/themes' as *;
@use '../styles/breakpoints' as *;

@mixin inputErrorStylesCore {
  border: 0.5px solid getValue(errorColor);
  background: lighten(getValue(errorColor), 40);
  outline-color: getValue(errorColor);
}

@mixin inputStylesCore {
  @include themify(input) {
    .input {
      border-radius: 5px;
      border: 0.5px solid getValue(placeholderColor);
      outline-color: getValue(placeholderColor);
      background: getValue(background);
      width: 100%;
      height: 50px;
      font-size: 16px;
      padding: 0 2.5rem 0 1rem;
      &:disabled {
        cursor: not-allowed;
      }
      &.error {
        @include inputErrorStylesCore;
      }
      &:active:not(.error),
      &:focus:not(.error) {
        outline: none;
      }
      @include md {
        height: 36px;
      }
    }

    .icon {
      color: getValue(placeholderColor);
    }
  }
}
