@use './variables.scss' as *;

// Extra Small devices
@mixin xsm {
  @media (max-width: #{$screen-sm-min}) {
    @content;
  }
}

// Small devices
// min-width means above small, (not including small)
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

// Extra extra large devices
@mixin xxl {
  @media (min-width: #{$screen-xxl-min}) {
    @content;
  }
}

@mixin sm-landscape {
  @media (min-height: #{$screen-sm-min}) {
    @content;
  }
}
