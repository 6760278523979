@use '../../styles/themes' as *;
@use '../inputStylesCore' as *;
@use '../inputFrame/inputFrameDescriptors' as *;

@include inputStylesCore;

@include themify(input) {
  @include inputFrameDescriptors;

  .self {
    span {
      user-select: none;
    }

    .disabled {
      cursor: not-allowed;

      input {
        cursor: not-allowed;
      }

      .label {
        cursor: not-allowed;
      }
    }

    .row {
      gap: 10px;
      align-items: start;
      justify-content: center;
    }

    &.error .input {
      color: getValue(background);
    }

    .input {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      color: getValue(background);
      background: getValue(background);

      width: 20px;
      height: 20px;
      padding: 0;
      user-select: none;
      cursor: pointer;

      svg {
        flex: 0 0 auto;
        height: 95%;
        width: 95%;
      }

      &.checkedOrMixed {
        @include themify {
          background-color: getValue(primaryBackground);
        }
      }

      &.error:not(.checkedOrMixed) {
        background: getValue(background);
      }

      &.error:focus {
        outline: getValue(errorColor) solid 1.5px;
      }
    }

    .label {
      height: auto;
      color: getValue(color);
      font-size: 1rem;
      user-select: none;
      cursor: pointer;
    }

    .error {
      font-size: 0.85rem;
    }
  }
}
