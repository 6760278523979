@use '../../styles/breakpoints.scss' as *;

.row {
  display: flex;
  flex-direction: column;

  @include sm {
    flex-direction: row;
  }
}

.forceRow {
  flex-direction: row;
}

.wrap {
  flex-wrap: wrap;
}
