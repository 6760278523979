@use '../styles/breakpoints' as *;
@use '../styles/themes' as *;

@include themify(button) {
  .self {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    user-select: none;
    outline-color: transparent;
    transition: all 0.5s;
    padding: 1rem;
  }

  .disabled {
    cursor: not-allowed;
  }

  .solid {
    @include getButtonStyle('solid');
  }

  .outline {
    @include getButtonStyle('outline');
  }

  .ghost {
    @include getButtonStyle('ghost');
  }

  .link {
    @include getButtonStyle('link');
  }

  .hidden {
    display: none;
  }
}
