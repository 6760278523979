@use '../../styles/themes' as *;
@use '../../styles/breakpoints' as *;
@use 'inputFrameDescriptors' as *;

@include themify(input) {
  .self {
    width: 100%;
    gap: 0.25rem;
  }

  .self span {
    user-select: none;
  }

  .row {
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
    gap: 0.5rem;
  }

  .inputRow {
    position: relative;
  }

  @include inputFrameDescriptors;
}
