@use '../../styles/themes' as *;

@mixin inputFrameDescriptors {
  .label {
    color: getValue(placeholderColor);
    font-size: 0.85rem;
    font-style: normal;
    font-weight: 400;
    flex-direction: row;
    display: flex;
    gap: 0.25em;
  }

  .info {
    font-size: 0.85rem;
    font-style: normal;
    font-weight: 400;
    color: getValue(color);
  }

  .validation {
    color: getValue(errorColor);
    font-size: 0.85rem;
    font-weight: 500;
    padding-top: 0.25rem;
    padding-left: 0.5rem;
  }

  .self.error {
    .input {
      border: 0.5px solid getValue(errorColor);
      background: lighten(getValue(errorColor), 40);
      outline-color: getValue(errorColor);
    }

    .error,
    .info {
      color: getValue(errorColor);
    }
  }
}
