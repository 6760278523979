/* make the customizations */
$theme-colors: (
  'primary': #274294,
  'nav-bottom': #1c2830,
  'borderFrame': rgba(28, 40, 48, 0.31),
  'hover': rgb(0, 0, 128),
  'secondary': rgb(39, 66, 148)
);

/*Breakpoints*/
@mixin respond-to($breakpoint) {
  @if $breakpoint == 'tiny' {
    @media (max-width: 400px) {
      @content;
    }
  }

  @if $breakpoint == 'small' {
    @media (min-width: 401px) {
      @content;
    }
  } @else if $breakpoint == 'medium' {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $breakpoint == 'large' {
    @media (min-width: 1200px) {
      @content;
    }
  }
}

@function theme-color($key: 'primary') {
  @return map-get($theme-colors, $key);
}

/* import bootstrap to set changes */
@import '~bootstrap/scss/bootstrap';
