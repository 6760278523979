@use '../../styles/themes' as *;
@use '../../styles/breakpoints' as *;
@use '../inputStylesCore' as *;

@include inputStylesCore;

.icon {
  position: absolute;
  right: 0;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding-right: 1rem;
}
